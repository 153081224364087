import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Typography,
  Box,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Map from "./Map"; // Assuming you have a Map component
import { stop_base_url, route_base_url } from "../utils";
import config from "../../../config.json";
import DashboardHeader from "../../../components/header/DashboardHeader";
import StarIcon from "../../../components/starIcon/StarIcon";
import { Description } from "@mui/icons-material";
function StopAddUpdatePage() {
  const [formData, setFormData] = useState({
    stopId: "",
    name: "",
    routeName: "",
    longitude: "",
    latitude: "",
    landmark: "",
    description: "",
    landmark: "",
    description: "",
  });
  const [selectedRoute, setSelectedRoute] = useState("");
  const [routesData, setRoutesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { stopId } = useParams(); // For editing existing stops
  const [position, setPosition] = useState({ latitude: "", longitude: "" });

  useEffect(() => {
    console.log("stopId:", stopId);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [routesResponse, stopResponse] = await Promise.all([
          axios.get(`${route_base_url}`),
          stopId
            ? axios.get(`${config.baseURL}/stop/id`, { params: { id: stopId } })
            : Promise.resolve(null),
        ]);

        setRoutesData(routesResponse.data);

        if (stopResponse) {
          const stopData = stopResponse.data;
          setFormData({
            stopId: stopData.stopId,
            name: stopData.name,
            routeName: stopData.routeName,
            longitude: stopData.longitude,
            latitude: stopData.latitude,
            landmark: stopData.landmark ? stopData.landmark : "",
            description: stopData.description ? stopData.description : "",
            landmark: stopData.landmark ? stopData.landmark : "",
            description: stopData.description ? stopData.description : "",
          });
          setSelectedRoute(stopData.routeName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [stopId]);

  useEffect(() => {
    if (formData.latitude && formData.longitude) {
      setPosition({
        latitude: formData.latitude,
        longitude: formData.longitude,
      });
    }
  }, [formData.latitude, formData.longitude]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRouteChange = (event) => {
    const routeName = event.target.value;
    setSelectedRoute(routeName);
    setFormData((prevState) => ({ ...prevState, routeName }));
  };

  const handleMapClick = (lat, lng) => {
    setPosition({ latitude: lat.toFixed(6), longitude: lng.toFixed(6) });
    setFormData((prevState) => ({
      ...prevState,
      latitude: lat.toFixed(6),
      longitude: lng.toFixed(6),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("stop:", formData);
    try {
      const stopData = {
        ...formData,
        organizationId: 1, // Assuming this is required
      };

      if (stopId) {
        await axios.put(`${stop_base_url}`, { ...stopData, id: stopId });
      } else {
        const resp = await axios.post(`${stop_base_url}`, stopData, {
          withCredentials: true,
        });
        console.log("response", resp.data);
      }

      // Update route coordinates if a route is selected
      if (selectedRoute) {
        const route = routesData.find((r) => r.name === selectedRoute);
        if (route) {
          const updatedCoordinates = [
            ...route.routeCoordinates,
            { longitude: formData.longitude, latitude: formData.latitude },
          ];

          await axios.patch(`${route_base_url}/coordinates/`, {
            id: route.id,
            routeType: "BI_DIRECTIONAL",
            routeCoordinates: updatedCoordinates,
          });
        }
      }

      navigate("/stops"); // Redirect to stops list page
    } catch (error) {
      console.error("Error saving stop:", error);
      // Handle error (show message to user)
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      {" "}
      <DashboardHeader title="Stops > Add " />
      <Container maxWidth="lg">
        <div className="addbutton_container" style={{ marginTop: "40px" }}>
          <button
            style={{ background: "rgba(32, 168, 224, 1)", color: "white" }}
            className="customButton_add"
          >
            View Stop Schedules
          </button>
        </div>
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          {/* <Typography variant="h4" gutterBottom>
          {stopId ? 'Edit Stop' : 'Add New Stop'}
        </Typography> */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Map
                  onPositionChange={handleMapClick}
                  initialLatitude={position.latitude}
                  initialLongitude={position.longitude}
                />
              </Grid>

              <Grid item xs={12}>
                <Box className="form_header" sx={{marginTop:"16px"}}>
                  <div className=" form_title">Stop Details</div>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="stopId">
                  Stop ID
                  <StarIcon />
                </InputLabel>
                <TextField
                  fullWidth
                  name="stopId"
                  placeholder="Type Stop ID"
                  value={formData.stopId}
                  onChange={handleChange}
                  required
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "black", // Thicker border
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="stopId">
                  Stop Name
                  <StarIcon />
                </InputLabel>
                <TextField
                  fullWidth
                  name="name"
                  placeholder="Type Stop Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "black", // Thicker border
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="stopId">
                  Longitude
                  <StarIcon />
                </InputLabel>
                <TextField
                  fullWidth
                  name="longitude"
                  placeholder="Type Longitude"
                  value={position.longitude}
                  onChange={(e) => {
                    setPosition((prev) => ({
                      ...prev,
                      longitude: e.target.value,
                    }));
                    handleChange(e);
                  }}
                  required
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "black", // Thicker border
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="stopId">
                  Latitude
                  <StarIcon />
                </InputLabel>
                <TextField
                  fullWidth
                  name="latitude"
                  placeholder="Type Latitude"
                  value={position.latitude}
                  onChange={(e) => {
                    setPosition((prev) => ({
                      ...prev,
                      longitude: e.target.value,
                    }));
                    handleChange(e);
                  }}
                  required
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "black",
                        // Thicker border
                      },
                    },
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} md={6}>
            <InputLabel htmlFor="stopId">Route</InputLabel>

              <FormControl fullWidth>
                <InputLabel>Route Name</InputLabel>
                <Select
                  value={selectedRoute}
                  onChange={handleRouteChange}
                 placeholder="Route Name"
                 
                 
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {routesData.map((route) => (
                    <MenuItem key={route.id} value={route.name}>
                      <Checkbox
                        checked={selectedRoute === route.name}
                        style={{ marginRight: 8 }}
                        
                      />
                      {route.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

             
  


            </Grid> */}

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="Landmark">
                  Landmark
                  <StarIcon />
                </InputLabel>
                <TextField
                  fullWidth
                  name="landmark"
                  placeholder="Type Landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                  required
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "black", // Thicker border
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <InputLabel
                  sx={{
                    fontWeight: "400",
                    size: "24px",
                    color: "black",
                    margin:'-8px'
                  }}
                >
                  Description
                </InputLabel>
                <TextField
                  name="description"
                  label="Write Description"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={formData.description}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      width: "174%",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "black",
                        width: "100%", // Thicker border
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                className="addbutton_container"
                style={{ marginTop: "40px" }}
              >
                <button
                  variant="outlined"
                  className="customButton_add"
                  onClick={() => navigate("/stops")}
                  style={{ background: "rgba(242, 242, 243, 1)" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="customButton_add"
                  style={{ marginRight: 10 }}
                >
                  {stopId ? "Submit" : "Submit"}
                </button>
              </div>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}

export default StopAddUpdatePage;

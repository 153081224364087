import React, { useEffect, useState } from "react";
import "./Overview.css";
import { card } from "./Carddata";
import config from "../../../config.json";
import axios from "axios";
import Map from "../../../components/map/Map";
import DashboardHeader from "../../../components/header/DashboardHeader";
import { StyledPaper } from "../../../components/formComponents";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Overview = () => {
  const navigate = useNavigate();

  const [vehicles, setVehicles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [stops, setStops] = useState([]);
  const [users, setUsers] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [overview, setOverview] = useState({
    users: 0,
    vehicles: 0,
    routes: 0,
    stops: 0,
    schedules: 0,
    activeVehicles: 0,
    inactiveVehicles: 0,
    deadVehicles: 0,
    totalAlerts: 0,
    vehicleAlerts: 0,
    userAlerts: 0,
    schedules: 0,
    activeVehicles: 0,
    inactiveVehicles: 0,
    deadVehicles: 0,
    totalAlerts: 0,
    vehicleAlerts: 0,
    userAlerts: 0,
  });

  async function getAllUsers() {
    try {
      const resp = await axios.get(`${config.baseURL}/user/organization/17`, {
        withCredentials: true,
      });
      return resp.data.users;
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      return [];
    }
  }
  async function getAllSchedules() {
    try {
      const resp = await axios.get(`${config.baseURL}/schedule/organization`, {
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      return [];
    }
  }
  async function getAllVehicles() {
    try {
      const resp = await axios.get(`${config.baseURL}/vehicle/organization`, {
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      return [];
    }
  }

  async function getAllRoutes() {
    try {
      const resp = await axios.get(`${config.baseURL}/route/organization`, {
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      console.error(error);
      return [];
    }
  }

  async function getAllStops() {
    try {
      const resp = await axios.get(`${config.baseURL}/stop/organization`, {
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const [usersData, vehiclesData, routesData, stopsData, schedulesData] =
          await Promise.all([
            getAllUsers(),
            getAllVehicles(),
            getAllRoutes(),
            getAllStops(),
            getAllSchedules(),
          ]);

        if (isMounted) {
          setUsers(usersData);
          setVehicles(vehiclesData);
          setRoutes(routesData);
          setStops(stopsData);
          setSchedules(schedulesData);

          setOverview({
            users: usersData.length,
            vehicles: vehiclesData.length,
            routes: routesData.length,
            stops: stopsData.length,
            schedules: schedulesData.length,
            activeVehicles: vehiclesData.filter(
              (vehicle) => vehicle.status === "ACTIVE"
            ).length,
            inactiveVehicles: vehiclesData.filter(
              (vehicle) => vehicle.status === "INACTIVE"
            ).length,
            deadVehicles: vehiclesData.filter(
              (vehicle) => vehicle.status === "DEAD"
            ).length,
            totalAlerts: 0,
            vehicleAlerts: 0,
            userAlerts: 0,
          })
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
    
<DashboardHeader title="Dashboard"/> 
<StyledPaper>
    <section className="overview">
      <div className="o-container">
        {card?.map((item, index) => {
          return (
            <div key={index} className="o-card">
  <div className="o-card-title">
    <h1>{item.title}</h1>
    <ArrowForwardIosIcon />
  </div>
  <div className="o-carddata">
  {item.icon}
    <p>{overview[item.name]}</p>
  
  </div>
</div>
          );
        })}
      </div>
      <div className="o-map">
        <Map stops={stops} routes={routes} />
      </div>
    </section>
    </StyledPaper>
    </>
  );
};

export default Overview;

import React, { useEffect, useState, useCallback } from "react";
import DashboardHeader from "../../../components/header/DashboardHeader";
import Paper from "@mui/material/Paper";
import AddRouteHeader from "./AddRouteHeader";
import AddRouteMap from "./AddRouteMap";
import config from "../../../config.json";
import StarIcon from "../../../components/starIcon/StarIcon";
import {
  colors,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import {
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Select,
} from "@material-ui/core";
import {
  Box,
  Container,
  MenuItem,
  makeStyles,
  Typography,
  Menu,
  Button,
  Checkbox,
  ListItemText,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./addRoute.css";
import {
  route_base_url,
  stop_base_url,
  vehicle_base_url,
} from "../../stops/utils";
import { useLocation } from "react-router-dom";
import { BorderStyle, Search } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router";

const RouteForm = ({
  routeData,
  handleRouteTypeChange,
  handleChange,
  allStopsData,
  origin,
  setOrigin,
  destination,
  setDestination,
  allVehiclesData,
  selectedVehicle,
  selectedStops,
  handleStopToggle,
  handleVehicleToggle,
  handleSubmit,
}) => {
  const [selectedRoute, setSelectedRoute] = useState(routeData.routeType);

  const handleRadioChange = (event) => {
    const newRouteType = event.target.value;
    setSelectedRoute(newRouteType);
    handleRouteTypeChange(event); // Update parent component's routeData state
  };

  // Conditional background color based on selectedRoute value
  const containerStyle = {
    backgroundColor:
      selectedRoute === "BI_DIRECTIONAL"
        ? "red"
        : selectedRoute === "SINGLE_DIRECTIONAL"
        ? "green"
        : "transparent",
    transition: "background-color 0.3s ease",
  };
};

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px", // Adjust the radius as needed
    },
    "& .MuiSelect-root": {
      borderRadius: "16px", // Adjust the radius as needed
    },
  },
  paper: {
    background: "#B7B7B7",
    color: "black",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: "100%",
    marginTop: "30px",
  },
  select: {
    borderRadius: "16px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));

const cancelBtnStyle = {
  color: "black",
  width: "340px",
  display: "flex",
  left: "-10px",
  marginTop: "30px",
  height: "55px",
  border: "1.5px solid black",
  borderRadius: "16px",
  marginLeft: "150px",
  marginBottom: "40px",
  fontSize: "large",
  fontWeight: "bold",
  fontFamily: "robot",
};
const submitBtnStyle = {
  color: "white",
  backgroundColor: "black",
  width: "340px",
  display: "flex",
  right: "-10px",
  marginTop: "30px",
  height: "55px",
  borderRadius: "16px",
  marginRight: "150px",
  marginBottom: "40px",
  fontSize: "large",
  fontWeight: "bold",
  fontFamily: "robot",
};

const selectedStopsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "3px",
  width: "60%",
  height: "250px",
  border: "2px solid black",
  borderRadius: "16px",
  color: "rgba(0, 140, 35, 1)",
};
const stopContainer = {
  backgroundColor: "#EFEFEF",
  height: "35px",
  width: "200px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "12px",
  borderRadius: "4px",
  margin: "10px",
  minWidth: "100px", // Set a minimum width to prevent overflow
  maxWidth: "200px", // Adjust maximum width as needed
};
const selectedVehiclesContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "3px",
  width: "60%",
  height: "250px",
  border: "2px solid black",
  borderRadius: "16px",
};
const VehiclesContainer = {
  backgroundColor: "#EFEFEF",
  height: "35px",
  width: "200px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "12px",
  borderRadius: "4px",
  margin: "10px",
  minWidth: "100px", // Set a minimum width to prevent overflow
  maxWidth: "200px", // Adjust maximum width as needed
};
const selectStyles = {
  width: "520px",
  border: "1px solid rgba(24, 48, 89, 1) ",
  BorderStyle: "none",
  height: "52px",
  borderRadius: "16px",
  padding: "18px",
};

function AddRoute() {
  const classes = useStyles();
  const [allStopsData, setAllStopsData] = useState([]);
  const [allVehiclesData, setAllVehiclesData] = useState([]);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [selectedStops, setSelectedStops] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [routePoints, setRoutePoints] = useState([]);

  const [routeData, setRouteData] = useState({
    routeId: "",
    routeName: "",
    routeType: "BI_DIRECTIONAL", // Default route type
  });
  const location = useLocation();
  const Navigate = useNavigate();
  const routeForUpdateData = location.state?.rowData; // Use optional chaining and provide default value
  console.log("route for update data", routeForUpdateData);
  const getStopInster = (stop) => {
    const clickedLng = Number(stop.longitude);
    const clickedLat = Number(stop.latitude);
    let origins = allStopsData.find((stop) => stop.id === origin);
    let destinations = allStopsData.find((stop) => stop.id === destination);
    // Find the closest segment of the route to insert the new point
    let insertIndex = 0;
    let minDistance = Infinity;
    console.log(
      origins,
      destinations,
      routePoints,
      "origin, destination, routePoints"
    );
    let tempOrigin = {
      lat: Number(origins.latitude),
      lng: Number(origins.longitude),
    };
    let tempDestination = {
      lat: Number(destinations.latitude),
      lng: Number(destinations.longitude),
    };
    let tempROutePoints = [tempOrigin, ...routePoints, tempDestination];
    console.log(tempROutePoints, "tempROutePoints");
    for (let i = 0; i < tempROutePoints.length - 1; i++) {
      const start = new window.google.maps.LatLng(
        tempROutePoints[i].lat,
        tempROutePoints[i].lng
      );
      const end = new window.google.maps.LatLng(
        tempROutePoints[i + 1].lat,
        tempROutePoints[i + 1].lng
      );
      const clickedPoint = new window.google.maps.LatLng(
        clickedLat,
        clickedLng
      );

      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          clickedPoint,
          new window.google.maps.LatLng(
            (start.lat() + end.lat()) / 2,
            (start.lng() + end.lng()) / 2
          )
        );

      if (distance < minDistance) {
        minDistance = distance;
        insertIndex = i;
      }
    }

    const newPoint = {
      lat: clickedLat,
      lng: clickedLng,
      isStop: true,
      name: stop.name,
    };

    console.log(newPoint, insertIndex, "event traget name");
    return { newPoint, insertIndex };
  };

  const handleStopToggle = useCallback(
    (stop) => {
      console.log("stop", stop);
      console.log("routePoints", routePoints);
      const index = routePoints.findIndex(
        (routePoint) =>
          (routePoint.lng === Number(stop.longitude) &&
            routePoint.lat === Number(stop.latitude)) ||
          routePoint.name === stop.name
      );
      console.log("index", index);
      if (index !== -1) {
        handleRemoveRoutePoint(index);
      } else {
        const { newPoint, insertIndex } = getStopInster(stop);
        console.log("newPoint, insertIndex", newPoint, insertIndex);
        handleAddRoutePoint(newPoint, insertIndex);
      }
      setSelectedStops((prevSelectedStops) => {
        const isSelected = prevSelectedStops.some(
          (selectedStop) => selectedStop.id === stop.id
        );
        return isSelected
          ? prevSelectedStops.filter(
              (selectedStop) => selectedStop.id !== stop.id
            )
          : [...prevSelectedStops, stop];
      });
    },
    [origin, destination, routePoints]
  );

  const handleVehicleToggle = useCallback((vehicle) => {
    setSelectedVehicle((prevSelectedVehicle) => {
      const isSelected = prevSelectedVehicle.some(
        (selectedVeh) => selectedVeh.id === vehicle.id
      );
      const newSelectedVehicle = isSelected
        ? prevSelectedVehicle.filter(
            (selectedVeh) => selectedVeh.id !== vehicle.id
          )
        : [...prevSelectedVehicle, vehicle];

      console.log(
        "Selected Vehicle IDs:",
        newSelectedVehicle.map((v) => v.id)
      );
      return newSelectedVehicle;
    });
  }, []);

  const handleAddRoutePoint = useCallback((newPoint, insertIndex) => {
    setRoutePoints((prevPoints) => {
      if (insertIndex === 0) {
        return [newPoint, ...prevPoints];
      }
      const updatedPoints = [...prevPoints];
      updatedPoints.splice(insertIndex, 0, newPoint);

      // Rename intermediate points
      return updatedPoints.map((point, index) => {
        if (!point.isStop) {
          return { ...point, name: `Intermediate ${index + 1}` };
        }
        return point;
      });
    });
    console.log("routePoints", routePoints);
  }, []);

  const handleRemoveRoutePoint = useCallback((index) => {
    setRoutePoints((prevPoints) => {
      if (prevPoints[index].isStop) {
        setSelectedStops((prevSelectedStops) => {
          const newSelectedStops = prevSelectedStops.filter(
            (selectedStop) =>
              Number(selectedStop.longitude) !== prevPoints[index].lng ||
              Number(selectedStop.latitude) !== prevPoints[index].lat
          );
          return newSelectedStops;
        });
      }
      const updatedPoints = prevPoints.filter((_, i) => i !== index);

      // Rename intermediate points
      return updatedPoints.map((point, index) => {
        if (!point.isStop) {
          return { ...point, name: `Intermediate ${index + 1}` };
        }
        return point;
      });
    });
  }, []);

  const handleChange = (e) => {
    setRouteData({ ...routeData, [e.target.id]: e.target.value });
  };

  const handleRouteTypeChange = (e) => {
    setRouteData({ ...routeData, routeType: e.target.value });
  };
  const handleSubmit = async () => {
    try {
      if (routeForUpdateData) {
        // If route data exists, perform update operation
        await updateRoute();
      } else {
        // If route data doesn't exist, perform add operation
        await addRoute();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  const addRoute = useCallback(async () => {
    try {
      // Prepare data to be submitted
      let origins = allStopsData.find((stop) => stop.id === origin);
      let destinations = allStopsData.find((stop) => stop.id === destination);
      let routeCoordinates = routePoints.map((point) => ({
        longitude: point.lng,
        latitude: point.lat,
      }));
      routeCoordinates = [
        {
          longitude: origins.longitude,
          latitude: origins.latitude,
        },
        ...routeCoordinates,
        {
          longitude: destinations.longitude,
          latitude: destinations.latitude,
        },
      ];

      const formData = {
        routeId: routeData.routeId,
        name: routeData.routeName,
        routeType: routeData.routeType,
        routeCoordinates: routeCoordinates,
        organizationId: 17,
      };

      console.log("Form Data:", formData); // Log the form data
      // Make API call to submit data
      const response = await axios.post(`${route_base_url}`, formData, {
        withCredentials: true,
      });
      console.log("Submission response:", response.data);
      // Check if the response status is successful   (for assinging the vehicle to the route)
      if (response.data) {
        // Extract the route ID from the response
        const routeId = response.data.id;
        console.log("routeId", routeId);

        // Prepare data to be submitted for assigning vehicles to the route
        const assignVehicles = {
          routeId: routeId,
          vehicleIds: selectedVehicle.map((vehicle) => vehicle.id),
        };
        console.log("Assign Vehicles Data:", assignVehicles); // Log assign vehicles data

        // Make API call to assign vehicles to the route
        axios
          .patch(`${route_base_url}/vehicles`, assignVehicles)
          .then((res) => {
            console.log("route vehicle response", res.data);
            // Reset form fields after successful submission
            setRouteData({
              routeId: "",
              routeName: "",
              routeType: "BI_DIRECTIONAL",
            });
            //setSelectedStops([]);
            //setSelectedVehicle([]);
          })
          .catch((error) => {
            console.log("error in assigning vehicle", error);
          });
      } else {
        console.error("Failed to assign vehicle:");
      }
      Navigate("/route");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  }, [origin, destination, routePoints]);
  useEffect(() => {
    if (routeForUpdateData) {
      setRouteData({
        routeId: routeForUpdateData.routeId,
        routeName: routeForUpdateData.name,
        routeType: routeForUpdateData.routeType,
      });
      let selectedStops = [...routeForUpdateData.stops];
      selectedStops = selectedStops.slice(1, selectedStops.length - 1);
      setSelectedStops(selectedStops || []);
      let routePoints = [];
      let inerCount = 0;
      for (let coordinate of routeForUpdateData.routeCoordinates.slice(
        1,
        routeForUpdateData.routeCoordinates.length - 1
      )) {
        let newPoint = {
          lat: Number(coordinate.latitude),
          lng: Number(coordinate.longitude),
        };
        let stop = selectedStops.find(
          (stop) =>
            stop.longitude === coordinate.longitude &&
            stop.latitude === coordinate.latitude
        );
        if (stop) {
          newPoint.isStop = true;
          newPoint.name = stop.name;
        } else {
          newPoint.isStop = false;
          newPoint.name = `Intermediate ${inerCount}`;
          inerCount++;
        }
        console.log("new point", newPoint);
        routePoints.push(newPoint);
      }
      setRoutePoints(routePoints);
      setSelectedVehicle(routeForUpdateData.vehicles || []);
      setOrigin(routeForUpdateData.stops[0].id);
      setDestination(
        routeForUpdateData.stops[routeForUpdateData.stops.length - 1].id
      );
    }
  }, []);

  const updateRoute = async () => {
    try {
      // Update route ID and name
      const updatingData = {
        id: routeForUpdateData.id,
        routeId: routeData.routeId,
        name: routeData.routeName,
      };
      console.log("this is form data", updatingData);
      const updateIdAndName = await axios.put(
        `${config.baseURL}/route/`,
        updatingData,
        { withCredentials: true }
      );
      console.log(
        "Route ID and Name updated successfully:",
        updateIdAndName.data
      );

      // Update route type and coordinates
      let origins = allStopsData.find((stop) => stop.id === origin);
      let destinations = allStopsData.find((stop) => stop.id === destination);
      let routeCoordinates = routePoints.map((point) => ({
        longitude: point.lng,
        latitude: point.lat,
      }));
      routeCoordinates = [
        {
          longitude: origins.longitude,
          latitude: origins.latitude,
        },
        ...routeCoordinates,
        {
          longitude: destinations.longitude,
          latitude: destinations.latitude,
        },
      ];

      const updateCoordinates = {
        id: routeForUpdateData.id,
        routeType: routeData.routeType,
        routeCoordinates: routeCoordinates,
      };
      console.log("updating coordinates data", updateCoordinates);

      const updateTypeAndCoordinates = await axios.patch(
        `${config.baseURL}/route/coordinates/`,
        updateCoordinates
      );

      console.log(
        "Route type and coordinates updated successfully:",
        updateTypeAndCoordinates.data
      );

      // Assign vehicles to the route

      const vehiclesAssigning = {
        routeId: routeForUpdateData.id,
        vehicleIds: selectedVehicle.map((vehicle) => vehicle.id),
      };
      if (vehiclesAssigning.vehicleIds.length > 0) {
        const assignVehicles = await axios.patch(
          `${route_base_url}/vehicles`,
          vehiclesAssigning
        );

        console.log(
          "Vehicles assigned to route successfully:",
          assignVehicles.data
        );
      }
      // Reset form fields after successful submission
      setRouteData({
        routeId: "",
        routeName: "",
        routeType: "BI_DIRECTIONAL",
      });
      setSelectedStops([]);
      setSelectedVehicle([]);
      Navigate("/routes");
    } catch (error) {
      console.error("Error updating route:", error);
    }
  };

  useEffect(() => {
    // Fetch stops and vehicles data when component mounts
    getStopsData();
    getVehiclesData();
  }, []);

  async function getStopsData() {
    try {
      const stopsResponse = await axios.get(
        `${config.baseURL}/stop/organization`,
        { withCredentials: true }
      );
      console.log("stops response", stopsResponse.data);
      setAllStopsData(stopsResponse.data);
    } catch (error) {
      console.log("error in fetching stops", error);
    }
  }

  async function getVehiclesData() {
    try {
      const vehiclesResponse = await axios.get(vehicle_base_url);
      console.log("vehicles response", vehiclesResponse.data);
      setAllVehiclesData(vehiclesResponse.data);
    } catch (error) {
      console.log("error in fetching vehicles", error);
    }
  }

  return (
    <>
      <DashboardHeader title="Route  > Add/Update " />
      <Paper style={{ margin: "20px", height: "100%", borderRadius: "10px" }}>
        <div
          className="form_header"
          style={{
            paddingLeft: "25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "25px",
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={routeData.routeType}
              onChange={handleRouteTypeChange}
              size="larger"
            >
              <FormControlLabel
                value="BI_DIRECTIONAL"
                control={<Radio color="#000000" />}
                label={
                  <Typography sx={{ fontWeight: "bolder" }}>
                    One Way Route
                  </Typography>
                } // Applied sx prop to Typography component for label
              />
              <FormControlLabel
                value="SINGLE_DIRECTIONAL"
                control={<Radio color="#000000" />}
                label={
                  <Typography sx={{ fontWeight: "bolder" }}>
                    Round Way Route
                  </Typography>
                } // Applied sx prop to Typography component for label
              />
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            className="add_route_btn"
            style={{ backgroundColor: "rgba(32, 168, 224, 1)", color: "white" }}
            sx={{ border: "2px solid black", fontWeight: "45px" }}
          >
            {" "}
            View Route Schedules
          </Button>
        </div>

        <Box sx={{ marginBottom: "32px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <InputLabel htmlFor="routeId" style={{ color: "black" }}>
                Route ID <StarIcon />
              </InputLabel>
              <TextField
                id="routeId"
                placeholder="Type Route ID"
                fieldName="enter id"
                variant="outlined"
                value={routeData.routeId}
                onChange={handleChange}
                fullWidth
                className={classes.roundedTextField} // Apply custom CSS class
                style={{ width: "520px", marginTop: "8px" }} // Increase width
              />
            </div>
            <div>
              <InputLabel htmlFor="routeId" style={{ color: "black" }}>
                Route Name <StarIcon />
              </InputLabel>
              <Box borderRadius="26px">
                {" "}
                {/* Apply borderRadius */}
                <TextField
                  id="routeName"
                  placeholder="Type Route Name"
                  value={routeData.routeName}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  className={classes.roundedTextField} // Apply custom CSS class
                  style={{ width: "520px", marginTop: "8px" }} // Increase width
                />
              </Box>
            </div>
          </Box>

          {routeData.routeType === "BI_DIRECTIONAL" && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: "25px" }}>
                <Box>
                  <InputLabel
                    htmlFor="startLocation"
                    style={{ color: "black", marginBottom: "8px" }}
                  >
                    Origin <StarIcon />
                  </InputLabel>
                  <Select
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                    displayEmpty
                    style={selectStyles}
                  >
                    <MenuItem value="" disabled>
                      <span style={{ color: "rgba(200, 203, 208, 1)" }}>
                        Select Origin
                      </span>
                    </MenuItem>
                    {allStopsData.map((stop) => (
                      <MenuItem key={stop.id} value={stop.id}>
                        {stop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </div>

              <div style={{ marginTop: "25px" }}>
                <Box style={{ borderRadius: "16px" }}>
                  <InputLabel
                    htmlFor="endLocation"
                    style={{ color: "black", marginBottom: "8px" }}
                  >
                    Destination <StarIcon />
                  </InputLabel>
                  <Select
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    displayEmpty
                    style={selectStyles}
                  >
                    <MenuItem value="" disabled>
                      <span style={{ color: "rgba(200, 203, 208, 1)" }}>
                        {" "}
                        Select Destination
                      </span>
                    </MenuItem>
                    {allStopsData.map((stop) => (
                      <MenuItem key={stop.id} value={stop.id}>
                        {stop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </div>
            </Box>
          )}
          {routeData.routeType === "SINGLE_DIRECTIONAL" && (
            <FormControl
              style={{ marginTop: "16px", width: "520px" }}
              variant="outlined"
              fullWidth
              className={classes.roundedTextField}
            >
              <InputLabel id="origin-label">Origin</InputLabel>
              <Select
                labelId="origin-label"
                id="origin"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                displayEmpty
                className={classes.select}
              >
                {allStopsData.map((stop) => (
                  <MenuItem key={stop.id} value={stop.name}>
                    {stop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <div style={{ position: "relative", width: "100%", height: "30%" }}>
          {/* Search Container */}
          <div
            className="search_container"
            style={{ position: "absolute", margin: "40px", zIndex: 1000 }}
          >
            <div className="search_text">
              <label htmlFor="search">
                <input
                  type="text"
                  id="search"
                  placeholder="Search"
                  className="search_text"
                />
              </label>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
                style={{ cursor: "pointer", marginLeft: "5px" }}
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </div>
          </div>

          <AddRouteMap
            origin={allStopsData.find((stop) => stop.id === origin)}
            destination={allStopsData.find((stop) => stop.id === destination)}
            routePoints={routePoints}
            routeType={routeData.routeType}
            onAddRoutePoint={handleAddRoutePoint}
          />
        </div>
        {/*        
        <Box>
          <h1 style={{ marginTop: "25px", marginBottom: "15px" }}>
            Stops and Intermediate Points
          </h1>
          <div style={selectedStopsContainer}>
            {routePoints.map((point, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                ml={1}
                style={stopContainer}
              >
                <ListItemText primary={point.name} />
                <IconButton onClick={() => handleRemoveRoutePoint(index)}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            ))}
          </div>
        </Box> */}

        <Box className="form_header">
          <div className="org-form-heading form_title">
            Stops & Points on this Route
          </div>
        </Box>
        <Box>
          {/* <h1 style={{ marginTop: "25px", marginBottom: "15px" }}>
              Existing Intermediate Stops
            </h1> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box style={selectedStopsContainer}>
              {selectedStops.length > 0 &&
                selectedStops.map((stop) => (
                  <Box
                    key={stop.id}
                    display="flex"
                    alignItems="center"
                    ml={1}
                    style={stopContainer}
                  >
                    <ListItemText primary={stop.name} />
                    <IconButton onClick={() => handleStopToggle(stop)}>
                      <HighlightOffIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Box>
                ))}
            </Box>
            <Box
              style={{
                width: "35%",
                height: "250px",
                border: "2px solid black",
                borderRadius: "16px",
              }}
            >
              {/* Render stops list with "Add New Stop" option */}
              <List
                style={{
                  overflowY: "auto",
                  maxHeight: "200px",
                  padding: 0,
                  scrollbarWidth: "round",
                  scrollbarColor: "black",
                }}
              >
                <div
                  style={{
                    height: "28px",
                    padding: "16px",
                    gap: "16px",
                    opacity: "0px",
                  }}
                >
                  Add Stops to this Route
                </div>
                <svg
                  width="470"
                  height="1"
                  viewBox="0 0 510 1"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line y1="0.5" x2="510" y2="0.5" stroke="#C8CBD0" />
                </svg>

                <ListItem>
                  <ListItemIcon>
                    <AddCircleOutlineIcon
                      sx={{ color: "rgba(32, 168, 224, 1)" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "rgba(32, 168, 224, 1)",
                        }}
                      >
                        Add New Stop
                      </span>
                    }
                  />{" "}
                  {/* Apply fontWeight: 'bold' */}
                </ListItem>
                {allStopsData &&
                  allStopsData.map((stop) => (
                    <ListItem key={stop.id}>
                      <ListItemIcon>
                        <Checkbox
                          style={{ color: "rgba(32, 168, 224, 1)" }}
                          edge="start"
                          checked={selectedStops.some(
                            (selectedStop) => selectedStop.id === stop.id
                          )}
                          onChange={() => handleStopToggle(stop)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={stop.name} />
                    </ListItem>
                  ))}
              </List>
            </Box>
          </div>
        </Box>

        <Box className="form_header">
          <div className="org-form-heading form_title">
            Vehicles on This Route
          </div>
        </Box>

        <Box>
          {/* <h1 style={{ marginTop: "25px", marginBottom: "15px" }}>
              Existing Intermediate vehicles
            </h1> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box style={selectedVehiclesContainer}>
              {selectedVehicle.length > 0 &&
                selectedVehicle.map((vehicle) => (
                  <Box
                    key={vehicle.id}
                    display="flex"
                    alignItems="center"
                    ml={1}
                    style={stopContainer}
                  >
                    <ListItemText primary={vehicle.name} />
                    <IconButton onClick={() => handleVehicleToggle(vehicle)}>
                      <HighlightOffIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Box>
                ))}
            </Box>
            <Box
              style={{
                width: "35%",
                height: "250px",
                border: "2px solid black",
                borderRadius: "16px",
              }}
            >
              {/* Render stops list with "Add New Stop" option */}
              <List
                style={{
                  overflowY: "auto",
                  maxHeight: "200px",
                  padding: 0,
                  scrollbarWidth: "round",
                  scrollbarColor: "black",
                }}
              >
                <div
                  style={{
                    height: "28px",
                    padding: "16px",
                    gap: "16px",
                    opacity: "0px",
                  }}
                >
                  {" "}
                  Add Vehicle to this Route
                </div>
                <svg
                  width="470"
                  height="1"
                  viewBox="0 0 510 1"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line y1="0.5" x2="510" y2="0.5" stroke="#C8CBD0" />
                </svg>

                <ListItem>
                  <ListItemIcon>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {/* Apply fontWeight: 'bold' */}
                      <AddCircleOutlineIcon
                        sx={{ color: "rgba(32, 168, 224, 1)" }}
                      />
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "rgba(32, 168, 224, 1)",
                        }}
                      >
                        Add New Vehicle
                      </span>
                    }
                  />{" "}
                  {/* Apply fontWeight: 'bold' */}
                </ListItem>
                {allVehiclesData?.vehicles &&
                  allVehiclesData.vehicles.map((vehicle) => (
                    <ListItem
                      key={vehicle.id}
                      onClick={() => handleVehicleToggle(vehicle)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          style={{ color: "rgba(32, 168, 224, 1)" }}
                          edge="start"
                          checked={selectedVehicle.some(
                            (selectedVeh) => selectedVeh.id === vehicle.id
                          )}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={vehicle.name} />
                    </ListItem>
                  ))}
              </List>
            </Box>
          </div>
        </Box>

        <div className="addbutton_container" style={{ marginTop: "40px" }}>
          <button
            style={{ cancelBtnStyle, background: "rgba(242, 242, 243, 1)" }}
            className="customButton_add"
            onClick={()=>Navigate('/route')}
          >
            Cancel
          </button>
          <button
            className="customButton_add"
            // style={submitBtnStyle}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </Paper>
    </>
  );
}

export default AddRoute;

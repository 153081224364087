import React from "react";
import "./Navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import { navData, useFilteredNavData } from "./NavData";

const Navbar = () => {

  const location = useLocation();
  const filteredNavData = useFilteredNavData(location.pathname);
  console.log(filteredNavData, "this is the filter navdata");

  // function for active link
  const isActiveRoute = (path) => {
    return (

      location.pathname === path || location.pathname.startsWith(`${path}/`)
    );
  };

  return (
    <section className="navbar">
      <nav className="n-container">
        <header className="n-logo">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Logo"
            style={{
              height: "44.2px",
              width: "48.2px",
              marginTop: "20px",
              marginLeft: "-4px",
            }}
          />
          <h1
            style={{
              fontWeight: "bold",
              paddingTop: "9px",
              fontFamily: "Roboto",
              color: "#000000",
            }}
          >
            RouteEye
          </h1>
        </header>
        <div className="n-data">
          {/* <div className="n-home">
                        
                        {  
                            navData?.map((item, index) => {
                                return (
                                    <NavLink to={item.path} key={index} className="sub-data" activeClassName="active-link">
                                        {item.icon}
                                        <p>{item.lable}</p>
                                    </NavLink>

                                )
                            })
                        }

                    </div> */}

          <div className="n-home">
            {filteredNavData?.map((item, index) => {
              return (
                <NavLink
                  to={item.path}
                  key={index}
                  //added class for active link
                  className={({ isActive }) =>
                    isActive || isActiveRoute(item.path)
                      ? "sub-data active-link"
                      : "sub-data"
                  }
                >
                  {item.icon}
                  <p>{item.label}</p>
                </NavLink>
              );
            })}
          </div>
          {/* <div className="n-setting">
                            {
                                setting?.map((item, index) =>{
                                    return (
                                        <NavLink to={item.path} key={index} className='sub-data'>
                                            {item.icon}
                                            {item.lable}
                                        </NavLink>
                                    )
                                })
                            }
                        </div> */}
          {/* Added logic for hovering effect in navbar  */}
        </div>
      </nav>
    </section>
  );
};

export default Navbar;

import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SearchStops from "./SearchStops";
import "./stops.css";
import StopsFilter from "./StopsFilter";
import axios from "axios";
import StopsTable from "./StopsTable";
import AddUpdateStops from "./AddUpdateStops/AddUpdateStops";
import { stop_base_url, route_base_url } from "./utils";
import DashboardHeader from "../../components/header/DashboardHeader";
import config from "../../config.json";
import { useNavigate } from "react-router";
import { usePermission } from "../../hooks/usePermission";

const Stops = () => {
  const [stopsData, setStopsData] = useState([]); // storing data from get all stops
  const [searchInput, setSearchInput] = useState(""); // Stores user's search input
  const [isAddUpdateDialogOpen, setIsAddUpdateDialogOpen] = useState(false);
  const [routesData, setRoutesData] = useState([]); // Stores all routes data
  const [routesByCoordinates, setRoutesByCoordinates] = useState([]); // Stores routes filtered by coordinates
  const [updatedStopsData, setUpdatedStopsData] = useState([]); // Stores stops data updated with route names
  const [selectedRouteFilter, setSelectedRouteFilter] = useState({
    name: "",
  }); //selecting routename for filtering
  const [selectedStopForUpdate, setSelectedStopForUpdate] = useState(null); // Selected stop for update
  const [selectedStopNameFilter, setSelectedStopNameFilter] = useState({
    name: "",
  }); // Selected stop name for filtering
  const navigate = useNavigate();
  const { hasPermission } = usePermission();

  // Compute filtered stops data based on search input and selected filters
  const filteredStopsData = useMemo(() => {
    // Filter by search input
    const searchFilteredData =
      searchInput && searchInput !== ""
        ? updatedStopsData.filter((stop) => {
            return (
              stop.stopId.toLowerCase().includes(searchInput.toLowerCase()) ||
              stop.name.toLowerCase().includes(searchInput.toLowerCase()) ||
              stop.routeName.toLowerCase().includes(searchInput.toLowerCase())
            );
          })
        : updatedStopsData;

    // Filter by selected stop name
    const selectedStopNameFilteredData =
      selectedStopNameFilter.name &&
      selectedStopNameFilter.name !== "All" &&
      selectedStopNameFilter.name !== ""
        ? searchFilteredData.filter(
            (stop) =>
              stop.name === selectedStopNameFilter.name ||
              selectedStopNameFilter.name === "All"
          )
        : searchFilteredData;

    // Filter by selected route name
    const selectedRouteFilteredData =
      selectedRouteFilter.name &&
      selectedRouteFilter.name !== "All" &&
      selectedRouteFilter.name !== ""
        ? selectedStopNameFilteredData.filter(
            (stop) =>
              stop.routeName === selectedRouteFilter.name ||
              selectedRouteFilter.name === "All"
          )
        : selectedStopNameFilteredData;

    return selectedRouteFilteredData;
  }, [
    updatedStopsData,
    searchInput,
    selectedStopNameFilter,
    selectedRouteFilter,
  ]);

  //onChange for search Input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    console.log("Search Input:", value);
    setSearchInput(value);
  };

  async function getRouteByStopCoordinates(lat, lng, orgId) {
    console.log("lat", lat, "lng", lng, "orgId", orgId);
    try {
      const response = await axios.get(`${config.baseURL}/route/coordinates/`, {
        params: {
          longitude: lng,
          latitude: lat,
          organizationId: orgId,
        },
      });
      console.log("routes ", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      //  throw error; // Re-throw the error for the caller to handle
    }
  }
  // fetching all the stopsData
  useEffect(() => {
    axios
      .get(`${config.baseURL}/stop/organization`, { withCredentials: true })
      .then((res) => {
        console.log("this is stops data ", res.data);
        const stops = res.data;
        for (const stop of stops) {
          console.log("this is stop ", stop);
          getRouteByStopCoordinates(
            stop.latitude,
            stop.longitude,
            stop.organizationId
          ).then((routes) => {
            console.log("this is routes and stop", routes, stop);
            stop["routes"] = routes;
          }).catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
        setStopsData(stops);
        console.log("this stops data ", stops);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // fetching all the routes
  useEffect(() => {
    axios
      .get(`${config.baseURL}/stop/organization`, { withCredentials: true })
      .then((res) => {
        console.log("routes ", res.data);
        setRoutesData(res.data);
        console.log("this routes data ", res.data);
      })
      .catch((error) => {
        console.log("could not fetch routes ", error);
      });
  }, []);

  //fetching routes by coordinates
  useEffect(() => {
    axios
      .get(`${route_base_url}/coordinates/`, {
        params: {
          organizationId: 1,
          longitude: stopsData.longitude,
          latitude: stopsData.latitude,
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log("routes by coordinates", res.data);
        setRoutesByCoordinates(res.data);
      }).catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [stopsData]);

  // Compute updated stops data based on routes by coordinates (adding routeName column to stopsData )
  useEffect(() => {
    const updatedStopsData = stopsData.map((stop) => {
      if (stop.routeName) {
        // Check if routeName is already present
        return stop;
      }

      // Find a matching route for the stop
      const matchingRoute = routesByCoordinates.find((route) => {
        const matchingCoordinate = route.routeCoordinates.find((coordinate) => {
          // Check if any route coordinate matches the stop's longitude and latitude
          return (
            coordinate.longitude === stop.longitude &&
            coordinate.latitude === stop.latitude
          );
        });
        return !!matchingCoordinate;
      });
      return {
        // Update the stop with the matching route's name if found
        ...stop,
        routeName: matchingRoute ? matchingRoute.name : "",
      };
    });

    // Check if updatedStopsData is different from stopsData
    const isDifferent =
      JSON.stringify(updatedStopsData) !== JSON.stringify(stopsData);

    if (isDifferent) {
      console.log("Updated stopsData:", updatedStopsData);
      setUpdatedStopsData(updatedStopsData);
    }
  }, [stopsData, routesByCoordinates]); // Depend on stopsData and routesByCoordinates

  // onChange for route select filter
  const handleRouteSelectChange = (event) => {
    setSelectedRouteFilter({ name: event.target.value });
  };

  // onChange for stop select filter
  const handleStopNameSelectChange = (event) => {
    // setSelectedStopName(event.target.value);
    setSelectedStopNameFilter({
      ...selectedStopNameFilter,
      name: event.target.value,
    });
  };

  // onChange for AddUpdateStops Dialog open
  const handleAddUpdateDialogOpen = (isOpen) => {
    navigate("/stop/add");
  };

  const handleAddUpdateDialogClose = () => {
    setIsAddUpdateDialogOpen(false);
  };
  // onChange for selected stop for updating
  const handleSelectedStopChange = (data) => {
    setSelectedStopForUpdate(data);
  };
  return (
    <>
      <DashboardHeader title="Stops" />

      <Paper style={{ marginLeft: "32px", marginRight: "32px" }}>
        <StopsHeader
          stopCount={stopsData.length}
          onAddUpdateDialogOpen={handleAddUpdateDialogOpen}
          updatedStopsData={updatedStopsData}
          navigate={navigate}
        />

        {/* <Divider /> */}
        <div className="search-filter-wrapper">
          <SearchStops onSearchChange={handleSearchChange} />
          <StopsFilter
            stopsData={stopsData}
            routesData={routesData}
            selectedRouteFilter={selectedRouteFilter}
            selectedStopNameFilter={selectedStopNameFilter}
            onRouteSelectChange={handleRouteSelectChange}
            onStopNameSelectChange={handleStopNameSelectChange}
          />
        </div>
        <StopsTable
          stopsData={stopsData}
          setStopsData={setStopsData}
          filteredStopsData={filteredStopsData}
          onAddUpdateDialogOpen={handleAddUpdateDialogOpen}
          onSelectedStopDataChange={handleSelectedStopChange} // Pass callback
          routesByCoordinates={routesByCoordinates}
          updatedStopsData={updatedStopsData}
          searchInput={searchInput}
          selectedRouteFilter={selectedRouteFilter}
        />
        <AddUpdateStops
          open={isAddUpdateDialogOpen}
          onClose={handleAddUpdateDialogClose}
          selectedStopForUpdate={selectedStopForUpdate}
          routesData={routesData}
        />
      </Paper>
    </>
  );
};

const StopsHeader = ({
  stopCount,
  onAddUpdateDialogOpen,
  updatedStopsData,
  navigate,
}) => {
  const { hasPermission } = usePermission();

  return (
    <>
      <div className="stop-header">
        <div>
          {/* <div className="stop-para">Manage All Stops in One Place.</div> */}
          {/* <div>
          <span className="stop-count">{stopCount} Stops</span>
        </div> */}
        </div>
        <div className="addbutton_container">
          <button
            variant="contained"
            className="customButton_add"
            style={{
              backgroundColor: "rgba(32, 168, 224, 1)",
              color: "rgba(255, 255, 255, 1)",
              marginLeft: "320px",
            }}
            sx={{ border: "2px solid black", fontWeight: "45px" }}
            onClick={() =>
              navigate("/stop/all/map", {
                state: { allStopsData: updatedStopsData },
              })
            }
          >
            {" "}
            View All Stops On Map
          </button>
          {hasPermission("/api/stop", "POST") && (
            <button
              variant="contained"
              className="customButton_add"
              style={{
                background: "rgba(249, 231, 25, 1)",
                color: "rgba(31, 36, 46, 1)",
              }}
              onClick={onAddUpdateDialogOpen}
            >
              + Add Stop
            </button>
          )}
        </div>
      </div>
      <Box className="Headercard" sx={{ marginTop: "16px" }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box className="Headercard_Total">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Total <br />
                  Stops
                </div>
                <div>
                  <span className="org-count">{stopCount}</span>
                </div>
              </div>
            </Box>
            <Box className="Headercard">
              <Grid container spacing={3}>
                <Grid item xs>
                  <Box className="Headercard_Total">
                    <div className="Headercard_Total_text">
                      <div className="org-para">
                        Total <br />
                        Stops
                      </div>
                      <div>
                        <span className="org-count">{stopCount}</span>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className="Headercard_Active">
                    <div className="Headercard_Total_text">
                      <div className="org-para">
                        Connected <br />
                        Routes
                      </div>
                      <div>
                        <span className="org-count">{stopCount}</span>
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Stops;

import React from "react";
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add";
import "./routes.css"
import { Navigate, useNavigate } from "react-router";
import { Box, Grid } from "@mui/material";
const RoutesHeader = ({totalRoutes}) => {
	const Navigate=useNavigate();

	return (

<>


<div className="addbutton_container"style={{marginTop:'32px'}}>
<button
		  variant="contained"
		  className="customButton_add"
		  onClick={()=> Navigate ('/route/add')}
		>
		  + Add Route
		</button>
	  </div>


	  <Box className="Headercard">
  <Grid container spacing={4}>
    <Grid item xs>
      <Box className="Headercard_Total">
		<div className="Headercard_Total_text">
		   <div className="org-para">
		  Total <br/>Routes
		    </div>
		  <div>
		  <span className="org-count">
			 {totalRoutes}
			</span>
		  </div>
		</div></Box>
    </Grid>

    <Grid item xs>
      <Box className="Headercard_Active">
        <div className="Headercard_Total_text">
        <div className="org-para">
		  Connected <br/>Routes
		  </div>
		  <div>
		  <span className="org-count">
			 {totalRoutes} 
			</span>
		  </div>
		</div></Box>
    </Grid>
  </Grid>
</Box>
	  </>
	);
  };
   
  export default RoutesHeader ;
  
  
  
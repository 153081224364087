import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
import { Grid, Paper, Typography, IconButton } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CalendarToday, Hail, Person } from "@mui/icons-material";
// eslint-disable-next-line no-unused-vars
const google = window.google;

const RouteTrackingComponent = () => {
  const location = useLocation();
  const routeData = location.state?.selectedRouteData;
  const [vehicleData, setVehicleData] = useState({});
  const [mapLoaded, setMapLoaded] = useState(false);
  const [directions, setDirections] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const routeBoundsRef = useRef(null);
  const routePathRef = useRef(null);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = routeData
    ? {
        lat: parseFloat(routeData.routeCoordinates[0].latitude),
        lng: parseFloat(routeData.routeCoordinates[0].longitude),
      }
    : { lat: 0, lng: 0 };

  const stopMarkers = routeData
    ? routeData.stops.map((stop) => ({
        position: {
          lat: parseFloat(stop.latitude),
          lng: parseFloat(stop.longitude),
        },
        label: stop.name,
      }))
    : [];

  const snapToRoute = (position) => {
    if (!routePathRef.current) return position;

    const point = new window.google.maps.LatLng(position.lat, position.lng);
    let closestPoint = point;
    let minDistance = Infinity;

    for (let i = 0; i < routePathRef.current.length - 1; i++) {
      const start = routePathRef.current[i];
      const end = routePathRef.current[i + 1];
      const projectedPoint = window.google.maps.geometry.spherical.interpolate(
        start,
        end,
        0.5
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          point,
          projectedPoint
        );

      if (distance < minDistance) {
        closestPoint = projectedPoint;
        minDistance = distance;
      }
    }

    return { lat: closestPoint.lat(), lng: closestPoint.lng() };
  };

  useEffect(() => {
    if (!routeData || !mapLoaded || !mapInstance) return;

    // Calculate route bounds once
    if (!routeBoundsRef.current && stopMarkers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      stopMarkers.forEach((marker) => {
        bounds.extend(marker.position);
      });
      routeBoundsRef.current = bounds;
      mapInstance.fitBounds(bounds);
    }

    const fetchVehicleData = async () => {
      const newData = {};
      for (const vehicle of routeData.vehicles) {
        try {
          const response = await fetch(
            `${config.baseURL}/location/latest/${vehicle.device_id}`
          );
          
          const data = await response.json();
          

          console.log("Vehicle data:", data);
          const position = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          };
          const snappedPosition = snapToRoute(position);
          const disTimeRes = await fetch(`${config.baseURL}/distance/time/stop`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ vehicleId: vehicle.id, longitude:destination.lng, latitude:destination.lat }),
            })
          const disTimeData = await disTimeRes.json();
          console.log(disTimeData);
          newData[vehicle.device_id] = {
            position: snappedPosition,
            speed: parseFloat(data.Speed),
            distance: disTimeData.distance,
            eta: disTimeData.eta,
          };
        } catch (error) {
          console.error(
            `Error fetching data for vehicle ${vehicle.device_id}:`,
            error
          );
        }
      }
      console.log("New vehicle data:", newData);
      setVehicleData(newData);
    };

    fetchVehicleData();
    const interval = setInterval(fetchVehicleData, 10000);

    // Calculate route
    const directionsService = new window.google.maps.DirectionsService();
    const origin = stopMarkers[0].position;
    const destination = stopMarkers[stopMarkers.length - 1].position;
    const waypoints = stopMarkers.slice(1, -1).map((marker) => ({
      location: marker.position,
      stopover: true,
    }));

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
          // Store the route path for snap-to-route functionality
          routePathRef.current = result.routes[0].overview_path;
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    

    return () => clearInterval(interval);
  }, [routeData, mapLoaded, mapInstance]);

  const onLoad = useCallback((map) => {
    setMapLoaded(true);
    setMapInstance(map);
  }, []);

  if (!routeData) {
    return <div>Loading route data...</div>;
  }

  console.log("Rendering map with vehicle data:", vehicleData);

  return (
    <><DashboardHeader title={`Routes > Monitor: ${routeData.name}`} />
    <div style={{padding:'32px'}}>
    
<div style={{marginBottom:'32px'}}>
    <Grid container spacing={2} >
      {/* Vehicles Card */}
      <Grid item xs={12} sm={4}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: 'rgba(88, 0, 191, 1)',
            color: 'white',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '12px',
          }}
        >
          <div className="route_track_card"> 
            <Typography variant="h6">Vehicles</Typography>
            <DirectionsCarIcon sx={{ fontSize: '40px', marginTop: '10px' }} />    
             <Typography variant="h5"> {routeData.vehicles.length}</Typography>     
          </div>
          
          <IconButton>
            
            <ArrowForwardIosIcon sx={{ color: 'white' }} />
            
          </IconButton>
        </Paper>
      </Grid>

      {/* Stop Card */}
      <Grid item xs={12} sm={4}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: 'rgba(0, 178, 191, 1)',
            color: 'white',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '12px',
          }}
        >
          <div className="route_track_card">
            <Typography variant="h6">Stops</Typography>
            <Hail sx={{ fontSize: '40px', marginTop: '10px' }} />
            {routeData.stops.length}
          </div>
          <IconButton>
            <ArrowForwardIosIcon sx={{ color: 'white' }} />
          </IconButton>
        </Paper>
      </Grid>

      {/* Schedule Card */}
      <Grid item xs={12} sm={4}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: 'rgba(191, 80, 0, 1)',
            color: 'white',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '12px',
          }}
        >
          <div className="route_track_card">
            <Typography variant="h6">Schedules</Typography>
            <CalendarToday sx={{ fontSize: '40px', marginTop: '10px' }} />
 
          </div>
          <IconButton>
            <ArrowForwardIosIcon sx={{ color: 'white' }} />
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
    </div>

      {/* <div>{routeData.name}</div>
      <div >
        <span>Vehicle Count: </span>
        {routeData.vehicles.length}
      </div>
      <div>
        <span>Stop Count: </span>
        {routeData.stops.length}
      </div> */}
      <LoadScript
        googleMapsApiKey="AIzaSyD7rqUpTzUpEbxm-Xc7ikltFJGzOhd92Qk"
        libraries={["geometry"]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={14}
          onLoad={onLoad}
        >
          {mapLoaded && (
            <>
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 3,
                    },
                  }}
                />
              )}
              {stopMarkers.map((marker, index) => (
                <Marker
                  key={index}
                  position={marker.position}
                  label={marker.label}
                  icon={
                    index === 0 || index === stopMarkers.length - 1
                      ? {
                          url:
                            index === 0
                              ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                              : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                          scaledSize: new window.google.maps.Size(40, 40),
                        }
                      : undefined
                  }
                />
              ))}
              {Object.entries(vehicleData).map(([deviceId, data]) => (
                <Marker
                  key={deviceId}
                  position={data.position}
                  icon={{
                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 6,
                    fillColor: "#4285F4",
                    fillOpacity: 1,
                    strokeWeight: 2,
                    rotation: 0,
                  }}
                />
              ))}
            </>
          )}
        </GoogleMap>
      </LoadScript>
      <div className="form_header">Vehicle Status</div>
      <div className="stop_strip" style={{marginTop:'-20px'}}>
        <table style={{ width: "100%", borderCollapse: "collapse",marginTop:'-7px' }}>
          <thead>
            <tr >
              <th
                style={{
                  padding: "8px",
                  textAlign: "left",
                  paddingLeft:'30px'
                }}
              >
                Vehicle ID
              </th>
              <th
                style={{
                
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Distance
              </th>
              <th
                style={{
                 
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                ETA
              </th>
              <th
                style={{
              
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Speed (km/h)
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(vehicleData).map(([deviceId, data]) => (
              <tr key={deviceId}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {deviceId}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {data.distance}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {data.eta}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {data.speed}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default RouteTrackingComponent;

import { Add } from "@mui/icons-material";
import { Button, Divider, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import DashboardHeader from "../../../components/header/DashboardHeader";

import {
  StyledFormButton,
  StyledFormControl,
  StyledFormInput,
  StyledFormInputLabel,
  StyledPaper,
} from "../../../components/formComponents";
import ImageUpload from "../../../components/imageUpload";
import useAdd from "../../../hooks/useAdd";
import useUpdate from "../../../hooks/useUpdate";
import {
  vehicleFormFields,
  vehicleFormFieldsDesc,
  vehicleFormFieldsLeftLower,
  vehicleFormFieldsRightLower,
  vehicleSiteFormField,
  vehicle_base_url,
} from "../../organization/utils";
import { fetchDrivers, fetchRoutes, fetchSites } from "../VehicleAPI";
import "./AddVehicle.css";
import config from "../../../config.json";
import ScheduleSelectionComponent from "../vehicleSchedule";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export default function AddVehicle() {
  const location = useLocation();

  const [vehicleFormData, setVehicleFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [sites, setSites] = useState([]);
  const [drivers, setDrivers] = useState([]);

  const navigate = useNavigate();
  const { addEntity } = useAdd(config.BaseUrl);
  const { updateEntity } = useUpdate(vehicle_base_url);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState([]);

  useEffect(() => {
    const fetchVehicleDataById = async (vehicleId, organizationId) => {
      console.log("id", vehicleId);
      try {
        const updateVehicleUrl = `${config.baseURL}/vehicle/id`;
        const response = await axios.get(
          updateVehicleUrl,
          {
            params: {
              vehicleId,
              organizationId,
            },
          },
          { withCredentials: true }
        );
        setVehicleFormData(response.data.vehicle);
        console.log("response", response.data.vehicle);
      } catch (error) {
        console.log(error);
      }
    };
    // if (location.state) {
    console.log("location", location.state);
    fetchVehicleDataById(location.state, 1);
  }, [location.state]);

  useEffect(() => {
    fetchRoutes(setRoutes); //function in VehicleAPI component
    fetchSites(setSites, 1); //function in VehicleAPI component
    fetchDrivers(setDrivers, 1); //function in VehicleAPI component
  }, []);

  const handleVehicleInputValue = (e, fieldName) => {
    const clonedVehicleFormData = { ...vehicleFormData };
    const inputValue = e.target.value;
    clonedVehicleFormData[fieldName] = inputValue;
    setVehicleFormData(clonedVehicleFormData);
  };

  const handleCloseModal = () => {
    console.log("close modal");
    console.log(selectedSchedules);
    setIsModalOpen(false);
  };

  const handleSchedulesSelected = async (selectedScheduleIds, vehicleId) => {
    try {
      await axios.patch(`${config.baseURL}/vehicle/schedule`, {
        scheduleIds: selectedScheduleIds,
        vehicleId: vehicleId,
      });
      alert("Schedules updated successfully!");
      setIsModalOpen(false);
      // Optionally, you can add logic here to refresh the vehicle's schedule data
    } catch (error) {
      console.error("Error updating schedules:", error);
      alert("Failed to update schedules. Please try again.");
    }
  };

  const handleSubmit = async () => {
    const clonedVehicleFormData = { ...vehicleFormData };
    console.log("clone", clonedVehicleFormData);

    if (location.state) {
      console.log("state cloned data", clonedVehicleFormData);
      const resp = await axios.put(
        `${config.baseURL}/vehicle`,
        clonedVehicleFormData,
        { withCredentials: true }
      );
      // const { responseData } = await updateEntity(
      //   { clonedVehicleFormData, id: location.state },
      //   location.state
      // );
      if (resp.data) {
        console.log("responseDAta.vehcile", resp.data);
        await handleSchedulesSelected(selectedSchedules, location.state);
        navigate("/Vehicle");
      }
    } else {
      const resp = await axios.post(
        `${config.baseURL}/vehicle`,
        clonedVehicleFormData,
        {
          withCredentials: true,
        }
      );
      // const { responseData } = await addEntity({
      //   ...clonedVehicleFormData,
      //   organizationId: 1,
      // });
      console.log("resp.........", (await resp).data);
      if (resp.data) {
        await handleSchedulesSelected(selectedSchedules, resp.data.vehicle.id);
        navigate("/Vehicle");
      }
    }
  };

  const handleCancel = () => {
    navigate("/Vehicle");
  };

  const handleDocumentUpload = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  useEffect(() => {
    console.log("selectedSchedules", selectedSchedules);
  }, [selectedSchedules]);
  const handleAddScheduleInVehicle = () => {
    setIsModalOpen(true);
  };

  return (
    <StyledPaper>
      <DashboardHeader title="Add Vehicle" />
      <div className="add-vehicle-form">
        <div className="upperHalf-form-field">
          <div className="upperHalf-form-field-left">
            {/* <div>
              {vehicleFormFields.map((item) => {
                return (
                  <FormFieldWrapper
                    item={item}
                    handleVehicleInputValue={handleVehicleInputValue}
                    vehicleFormData={vehicleFormData}
                    key={item.id}
                    routes={routes}
                    // sites={sites}
                    // drivers={drivers}
                  />
                );
              })}
            </div> */}
          </div>
          <div className="img-site-field">
            {/* <div className="vehicle-img">
              <ImageUpload />
            </div> */}
            <div>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleAddScheduleInVehicle}
                className="add-schedule-btn"
              >
                + Add/Update Schedule
              </Button> */}
              <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>Select Schedules</DialogTitle>
                <DialogContent>
                  <ScheduleSelectionComponent
                    vehicleId={location.state}
                    onSchedulesSelected={setSelectedSchedules}
                    closeWindow={handleCloseModal}
                  />
                </DialogContent>
              </Dialog>
            </div>
            {/* <div className="site-form-field">
              {vehicleSiteFormField.map((item) => {
                return (
                  <FormFieldWrapper
                    item={item}
                    handleVehicleInputValue={handleVehicleInputValue}
                    vehicleFormData={vehicleFormData}
                    key={item.id}
                    sites={sites}
                  />
                );
              })}
            </div> */}
          </div>
        </div>
      </div>

      <div className="form-container">
        <div>
          <div>
            {vehicleFormFields.map((item) => {
              return (
                <FormFieldWrapper
                  item={item}
                  handleVehicleInputValue={handleVehicleInputValue}
                  vehicleFormData={vehicleFormData}
                  key={item.id}
                  routes={routes}
                  sites={sites}
                  drivers={drivers}
                />
              );
            })}
          </div>
        </div>

        <div className="img-site-field">
          {/* <div className="vehicle-img">
            <ImageUpload />
          </div> */}

          <div>
            <button
              className="button-container customButton_add "
              style={{
                color: "rgba(255, 255, 255, 1)",
                background: "rgba(32, 168, 224, 1)",
                width: "554px",
              }}
              onClick={handleAddScheduleInVehicle}
            >
              Assign Schedule
            </button>
          </div>
          <div className="site-form-field">
            {vehicleSiteFormField.map((item) => {
              return (
                <FormFieldWrapper
                  item={item}
                  handleVehicleInputValue={handleVehicleInputValue}
                  vehicleFormData={vehicleFormData}
                  key={item.id}
                  sites={sites}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div>
        <div className="form-container">
          <div>
            {vehicleFormFieldsLeftLower.map((item) => {
              return (
                <FormFieldWrapper
                  item={item}
                  handleVehicleInputValue={handleVehicleInputValue}
                  vehicleFormData={vehicleFormData}
                  key={item.id}
                />
              );
            })}
          </div>
          <div>
            {vehicleFormFieldsRightLower.map((item) => {
              return (
                <FormFieldWrapper
                  item={item}
                  handleVehicleInputValue={handleVehicleInputValue}
                  vehicleFormData={vehicleFormData}
                  key={item.id}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* <Divider /> */}
      <div
        className="desc-form-field"
        style={{ width: "100%", padding: "0px" }}
      >
        {vehicleFormFieldsDesc.map((item) => {
          return (
            <FormFieldWrapper
              item={item}
              handleVehicleInputValue={handleVehicleInputValue}
              vehicleFormData={vehicleFormData}
              key={item.id}
            />
          );
        })}
      </div>

      {/* <Divider /> */}
      <div>
        {/* <div>
            Upload Documents (RC Book, Emission Test Report, Insurance Policy
            etc.)
          </div> */}

        {/* <div className="form_header">
          {" "}
          <div className="org-form-heading form_title">Documents </div>
        </div>

        <button
          variant="outlined"
          className="customButton_add"
          onClick={handleDocumentUpload}
          startIcon={<Add />}
          style={{
            textTransform: "none",
            marginLeft: "20px",
            background: "rgba(32, 168, 224, 1)",
            color: "white",
          }} // Prevent automatic capitalization
        >
          Attach Document
        </button> */}
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {selectedFile && <p>Selected file: {selectedFile.name}</p>}
      </div>

      <div className="addbutton_container">
        <button
          variant="outlined"
          style={{ background: "rgba(242, 242, 243, 1)" }}
          onClick={handleCancel}
          className="customButton_add"
        >
          Cancel
        </button>
        <button
          variant="contained"
          onClick={handleSubmit}
          className="customButton_add"
        >
          Submit
        </button>
      </div>
    </StyledPaper>
  );
}

const DropDownWrapper = ({
  item,
  vehicleFormData,
  handleDropdownChange,
  options = [],
}) => {
  const dropDownOptions = (item, options) => {
    if (item.id === "routes") {
      return options.map((option) => (
        <MenuItem value={option.id}>{option.name}</MenuItem>
      ));
    } else if (item.id === "sites") {
      return options.map((option) => (
        <MenuItem value={option.id}>{option.siteId}</MenuItem>
      ));
    } else if (item.id === "drivers") {
      return options.map((option) => (
        <MenuItem value={option.id}>
          {option.first_name + " " + option.last_name}
        </MenuItem>
      ));
    }
  };
  return (
    <>
      {/* <InputLabel id="select-label">{item.placeholder}</InputLabel> */}
      <Select
        id={item.id}
        value={vehicleFormData[item.fieldName]}
        onChange={(e) => handleDropdownChange(e, item.fieldName)}
        input={<StyledFormInput />}
        placeholder={item.placeholder}
        label={item.placeholder}
      >
        {dropDownOptions(item, options)}
      </Select>
    </>
  );
};

const FormFieldWrapper = ({
  handleVehicleInputValue,
  item,
  vehicleFormData,
  routes = [],
  drivers = [],
  sites = [],
}) => {
  return (
    <StyledFormControl variant="standard" fullWidth>
      <StyledFormInputLabel shrink htmlFor={item.id}>
        {item.label}
      </StyledFormInputLabel>

      {item.type === "select" ? (
        <>
          <DropDownWrapper
            item={item}
            vehicleFormData={vehicleFormData}
            handleDropdownChange={handleVehicleInputValue}
            options={
              item.id === "routes"
                ? routes
                : item.id === "sites"
                ? sites
                : drivers
            }
          />
        </>
      ) : (
        <StyledFormInput
          id={item.id}
          onChange={(e) => handleVehicleInputValue(e, item.fieldName)}
          value={vehicleFormData[item.fieldName] || ""}
          placeholder={item.placeholder}
        />
      )}
    </StyledFormControl>
  );
};

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../config.json' 
import { useParams } from 'react-router'
import DashboardHeader from '../../components/header/DashboardHeader';
import RoleAccessHeader from './RoleAccessHeader';
import { Paper } from '@mui/material';
import RoleAccessTable from './RoleAccessTable';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

let currentRole;
let currentRoleFeatures = [];
let availableFeatures = [];

const RoleAccess = () => {
  const navigate = useNavigate();
  const [selectedRoleData,setSelectedRoleData]=useState([])
  const [getAllRolesData,setGetAllRolesData]=useState([])
  const [totalFeatures,setTotalFeatures]=useState([])
  const location = useLocation();
  const id = location.state ? location.state.id : null; 
  // Now you can use the id as needed in your component
  console.log("Received id:", id);

  async function getRoleById(id) {
    try {
      const response = await axios.get(`${config.baseURL}/role/${id}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.log("Error fetching the role by id:", error);
      if (error.response && error.response.status === 401) {
        navigate("/");
        return [];
      }
      throw error;
    }
  }

  async function getAllTheAvailableFeatures() {
    try {
      const response = await axios.get(`${config.baseURL}/feature/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.log("Error fetching All the available features for user:", error);
      throw error;
    }
  }

  async function assignFeatureToTheRole(roleId, featureIds) {
    try {
      const response = await axios.post(`${config.baseURL}/role/feature`, {
        roleId: roleId,
        featureId: featureIds,
      });
      return response.data;
    } catch (error) {
      console.log("Error assigning the features to the role:", error);
      throw error;
    }
  }

  async function getAllCommonFeatures() {
    return currentRoleFeatures.filter((feature) =>
      availableFeatures.some(
        (availableFeature) => availableFeature.id === feature.id
      )
    );
  }
  async function getAllRoles() {
    try {
      const response = await axios.get(`${config.baseURL}/role/organization/`, {
        withCredentials: true,
      });
      console.log("this is features", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching features:", error);
      throw error;
    }
  }


  
  useEffect(() => {
    if (id) {
      async function fetchData() {
        try {
          const response = await getRoleById(id);
          setSelectedRoleData(response.role);
          console.log(response);
        } catch (error) {
          console.log("Error fetching the role by id:", error);
        }
        getAllRoles()
        .then(data => {
          console.log('All roles:', data); // Add this line to console log the data
          setGetAllRolesData(data.roles);
        })
        .catch(error => console.error('Error fetching roles:', error));
      }
      fetchData();
    }
  }, [id]);
  useEffect(() => {
    getAllTheAvailableFeatures()
      .then(data => 
        setTotalFeatures(data) )
  
      .catch(error => console.error('Error fetching features:', error));
    }, []);

  return (
    <>
    <DashboardHeader title="Role Access Assignment"/>
    <Paper style={{margin:"20px",height:"100%",borderRadius:"20px"}}>
    <RoleAccessHeader selectedRoleData={selectedRoleData}
      getAllRolesData={getAllRolesData}
      totalFeatures={totalFeatures.length}
      />
    <div style={{marginTop:"30px"}}>
    <RoleAccessTable getAllTheAvailableFeatures={getAllTheAvailableFeatures}
    getAllRoles={getAllRoles}
    selectedRoleData={selectedRoleData}
    assignFeatureToTheRole={assignFeatureToTheRole}
     />
    </div>
    </Paper>
    </>
  )
}

export default RoleAccess
import React from 'react'
import {Routes as RouterRoutes,Route,Navigate} from 'react-router'
import Login from '../pages/login/Login'
import Home from '../pages/home/Home'
import Feature from '../pages/feature/Feature'
import Dashboard from '../pages/dashboard/Dashboard'
import Role from '../pages/role/Role'
import RoleAccess from '../pages/role/RoleAccess'
import Users from '../pages/Users/Users'
import AddUser from '../pages/Users/AddUser'
import Driver from '../pages/driver/Driver'
import AddDriver from '../pages/driver/AddDriver'
import Site from '../pages/site/Site'
import AddSite from '../pages/site/AddSite'
import Schedule from '../pages/Schedule/Schedule'
import AddSchedule from '../pages/Schedule/AddSchedule'
import Organization from '../pages/organization/Organization'
import RegisterOrganization from '../pages/organization/register/RegisterOrg'


const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<Login />} />
      <Route path="/acess" element={<Feature />} />
      <Route path="/role" element={<Role />} />
      <Route path="/user" element={<Users />} />
      <Route path="/driver" element={<Driver />} />
      <Route path="/driver/add" element={<AddDriver />} />
      <Route path="/site" element={<Site />} />
      <Route path="/site/add" element={<AddSite />} />
      {/* <Route path='/schedule' element={<Schedule />} /> */}
      <Route path="/schedule/add" element={<AddSchedule />} />
      <Route path="/schedule/:scheduleId" element={<AddSchedule />} />
      <Route path="/role/:Id" element={<RoleAccess />} />
      <Route path="/user/:Id" element={<AddUser />} />
      <Route path="/site/:siteId" element={<AddSite />} />
      <Route path="/register" element={<RegisterOrganization />} />

      <Route path="*" element={<Home />}></Route>
    </RouterRoutes>
  );
}

export default Routes

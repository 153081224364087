import { Box, Button,InputAdornment, Snackbar, TextField, Typography,InputLabel,FormControl, Grid } from '@mui/material'
import React, { useState } from 'react'
// import AddIcon from "@mui/icons-material/Add"
import SearchIcon from '@mui/icons-material/Search'
import StarIcon from '../../components/starIcon/StarIcon'
import './role.css'

function RoleHeader({roleList,totalRoles,createRole,onSearchChange,setCurrentRoleData}) {
  const [formData,setFormData]=useState({
    name:'',
    description:''
  })
  const [formErrors, setFormErrors] = useState({
    name: '',
    description: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage,setErrorMessage]=useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target

    const updatedErrors = { ...formErrors };
    delete updatedErrors[name];
    setFormErrors(updatedErrors);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
     // Check if any required field is empty
      // Validate fields
    let errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.description) {
      errors.description = 'Description is required';
    }
    setFormErrors(errors);

    // Check if any errors exist
    if (Object.keys(errors).length > 0) {
      return;
    }
    const newFeature = {
      name: formData.name,
      description: formData.description,
      organizationId:1
    };
    try {
      const result = await createRole(newFeature);
      console.log('Feature created successfully:', result);
      // Reset form fields
      setFormData({
        name: '',
        description: '',
      });
       // Update role list with the new role
       roleList.push(result);
       // Display success message
       setSuccessMessage('Role created successfully!');
       setSnackbarOpen(true);
       
      // Optionally, update the parent component or notify user
    } catch (error) {
      console.error('Error creating feature:', error)
       // Display error message
    setErrorMessage('Failed to create role. Please try again.');
    setSnackbarOpen(true);
     // Clear success message
     setSuccessMessage('');
   
    }
    
  };
  // to close the snackbar (message display box)
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
	<>


<Box className="Headercard" sx={{width:"33%"}}>
      <Grid item xs>
      <Box className="Headercard_Total">
         <div className="Headercard_Total_text">
         <div className="org-para">
       Total<br/>User Roles
       </div>
       <div>
       <span className="org-count">
       5
       </span>
       </div>
     </div>
        </Box>
      </Grid>
      </Box>


      <div className="form_header" > <div className="form_title">Add User Roles </div></div>
      



      <div className="form-container">
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="Role Name"
                sx={{
                  fontWeight: "400",
                  size: "24px",
                  paddingBottom: "8px",
                  color: "black",
                }}
              >
               Role Name
                <StarIcon />
              </InputLabel>
              <TextField
        id="outlined-required"
        placeholder="Type Role Name"
        label="Role name"
        name='name'
        value={formData.name}
        error={!!formErrors.name}
        helperText={formErrors.name}
        onChange={handleChange}
        fullWidth

        required
        InputProps={{
          style: {
           borderRadius:"16px",
          
          }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '2px',
              borderColor: ' rgba(24, 48, 89, 1)', // Thicker border
            },
          },
        }}
      />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="lastName"
                sx={{
                  fontWeight: "400",
                  size: "24px",
                  paddingBottom: "8px",
                  color: "black",
                }}
              >
                Role Description
                <StarIcon />
              </InputLabel>
              <TextField
        id="outlined-required-2"
       placeholder=" Type Role Description"
       label="Role Description"
        name='description'
        error={!!formErrors.description}
        helperText={formErrors.description}
        value={formData.description}
        onChange={handleChange}
        required
        fullWidth

        InputProps={{
          style: {
           borderRadius:"16px"
          }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '2px',
              borderColor: 'rgba(24, 48, 89, 1)', // Thicker border
            },
          },
        }}
        
      />
            </Grid>
          </div>
















<Box className="addbutton_container">
<button style={{background:'rgba(242, 242, 243, 1)',color:"rgba(31, 36, 46, 1)"}}
        variant="contained"
       className="customButton_add"
       >
       Cancel
     </button>

  <button
    variant="contained"
    className="customButton_add"
    onClick={handleSubmit}
  >
      Submit
  </button>

  {successMessage && (
    <Typography variant="body1" color="success" sx={{ marginTop: '10px', marginLeft: '20px' }}>
      {successMessage}
    </Typography>
  )}

</Box>


  <div className='search-filter-wrapper'>
    <div className="o-searchbar shared-bg">
      <TextField
        size="small"
        id="search-role"
        label="Search"
        onChange={onSearchChange}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{color:'rgba(24, 48, 89, 1)'}}/>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: 'black' },
        }}
      />
    </div>
  </div>

  <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={handleCloseSnackbar}
    message={successMessage || errorMessage}
    action={
      <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
        Close
      </Button>
    }
  />
	</>
  )
}

export default RoleHeader
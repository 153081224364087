function sortByParam(param, data) {
  return data.sort((a, b) => {
    if (a[param] < b[param]) {
      return -1;
    }
    if (a[param] > b[param]) {
      return 1;
    }
    return 0;
  });
}

export default sortByParam;

